import {
    Autocomplete,
    Box,
    Button,
    Chip,
    CircularProgress,
    Grid,
    Input,
    InputLabel,
    LinearProgress,
    TextField,
} from '@mui/material';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSupportedLanguages } from '../../components/layout/hooks/useSupportedLanguages';
import { UserContext } from '../../context';
import { useMsal } from '@azure/msal-react';
import { usePrevious } from '../../hooks/usePrevious';
import { api } from '../../services';
import { generateToken } from '../../services/fetch-api/auth/authToken';
import TreeView from '../../components/treeview';
import TreeSearch from '../../components/treeview/TreeSearch';
import SaveIcon from '@mui/icons-material/Save';
import { AppIdEnum } from '../../types/app';
import { CreateAdHocInviteForm } from '../../types/invite';
import { equal } from '../../util/object';
import AdHocManagersFields from './AdHocManagersFields';
import type { IAutocomplete } from '../../types';
import { InputContainer, CornerStoneData, Error } from '../../styled';
import { defaultValues } from '../../constants';
import { handleRequiredInput, handleEmptyInputs } from '../../helpers';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useAdHocInvite } from './hooks/useAdHocInvite';
import { useChosenBusinessSegment } from './hooks/useChosenBusinessSegment';
import { useIntelexLocationSearch } from './hooks/useIntelexLocationSearch';
import { useLocationSearch } from './hooks/useLocationSearch';
import { useSupervisorsSearch } from './hooks/useSupervisorsSearch';
import { useUpdateUserObject } from './hooks/useUpdateUserObject';
import { useUserDataSearch } from './hooks/useUserDataSearch';
import { findLargestLevelWithId, findOrgUnit, findPathToId, populateOrgUnitLevels } from './util';

interface AdHocElevateOnlineProps {
    page?: 'change-data';
}

const AdHocElevateOnline = (props: AdHocElevateOnlineProps) => {
    const { state, dispatch } = useContext(UserContext);
    const { t } = useTranslation();
    const { instance, accounts } = useMsal();
    const supervisorsInput = useRef<HTMLElement>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItem, setSelectedItem] = useState<any>();
    const [mandatoryLoading, setMandatoryLoading] = useState(false);
    const [lindeManagerRequired, setLindeManagerRequired] = useState(false);
    const [managersMandatoryOnSubmit, setManagersMandatoryOnSubmit] = useState(false);
    const [formValues, setFormValues] = useState<CreateAdHocInviteForm>(defaultValues);
    const userInput = useRef<HTMLElement>(null);
    const [userObject, setUserObject] = useState<any>();
    const [userObjectData, setUserObjectData] = useState<CreateAdHocInviteForm>();
    const [locationName, setLocationName] = useState<any>();
    const [supervisorPrefill, setSupervisorPrefill] = useState<any>();
    const [supervisorPrefillIsLoading, setSupervisorPrefillIsLoading] = useState<any>();
    const [lindeManagerPrefill, setLindeManagerPrefill] = useState<any>();
    const [intelexLocation, setIntelexLocation] = useState<any>();
    const { updateUserObject, isUpdatingUserObject } = useUpdateUserObject();
    const { createInvite, isCreatingInvite } = useAdHocInvite();
    const isSubmitLoading = isUpdatingUserObject || isCreatingInvite;
    const { languageOptions, isLoadingLanguageOptions: languageLoading } = useSupportedLanguages();
    const {
        countryCodes,
        businessRelationships,
        orgUnits,
        externalPositions,
        cornerstoneLabels,
        userSupportInfo,
        supportInfo,
        mandatoryFields,
        isLoading: isBusinessSegmentLoading,
    } = useChosenBusinessSegment({ type: 'online' });
    const {
        searchLocations,
        isLocationLoading: locationLoading,
        searchResults,
    } = useLocationSearch();
    const {
        searchLocations: searchIntelexLocations,
        isLocationLoading: intelexLocationLoading,
        searchResults: intelexLocations,
    } = useIntelexLocationSearch();
    const {
        searchResults: supervisors,
        isSupervisorsLoading: supervisorLoading,
        searchSupervisors,
    } = useSupervisorsSearch();
    const {
        searchUserData,
        userData: usersData,
        isLoadingUserDataSearch: isLoading,
    } = useUserDataSearch();
    const prevBusinessSegment = usePrevious(state.chosenBusinessSegment);

    const handleSubmit = (event: any) => {
        if (!handleEmptyInputs(mandatoryFields, formValues, 'change-data')) {
            event.preventDefault();
            if (props.page === 'change-data' && state.user) {
                //do this only if we are on change data page
                updateUserObject(formValues, userObject?.id, (userObj) => {
                    setUserObjectData(userObj);
                    setFormValues(userObj);
                });
            } else {
                createInvite(formValues, 'online');
            }
        } else {
            handleRequiredInput(
                event,
                mandatoryFields,
                formValues,
                setManagersMandatoryOnSubmit,
                setLindeManagerRequired,
                state,
            );
        }
    };

    const handleInputChange = (event: any) => {
        let errorMsg: any = document.querySelectorAll('.errorMsg');
        errorMsg.forEach((e: any) => {
            e.style.display = 'none';
            setManagersMandatoryOnSubmit(false);
        });
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleAutocompleteChange = (
        value: IAutocomplete | null | string | undefined | number,
        name: string,
    ) => {
        let errorMsg: any = document.querySelectorAll('.errorMsg');
        errorMsg.forEach((e: any) => {
            e.style.display = 'none';
            setManagersMandatoryOnSubmit(false);
        });

        setFormValues((prevValues: any) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    function handleManagerSelection(value: Partial<CreateAdHocInviteForm>) {
        let errorMsg: any = document.querySelectorAll('.errorMsg');
        errorMsg.forEach((e: any) => {
            e.style.display = 'none';
            setManagersMandatoryOnSubmit(false);
        });

        setFormValues((prevValues) => ({
            ...prevValues,
            ...value,
        }));
    }

    function handleSelectOrgUnit(value: string) {
        let errorMsg: any = document.querySelectorAll('.errorMsg');
        errorMsg.forEach((e: any) => {
            e.style.display = 'none';
        });
        setSelectedItem(value);
    }

    useEffect(() => {
        if (orgUnits) {
            const path = findPathToId(
                orgUnits,
                selectedItem?.data?.internalId || selectedItem?.internalId,
            );

            const orgLevels = populateOrgUnitLevels(path);
            setFormValues((prevState) => ({
                ...prevState,
                ...orgLevels,
            }));
        }
    }, [orgUnits, selectedItem]);

    useEffect(() => {
        if (!state.chosenBusinessSegment || equal(state.chosenBusinessSegment, prevBusinessSegment))
            return;

        setFormValues({
            ...defaultValues,
            language: state.chosenBusinessSegment.defaultLanguage as string,
        });

        setUserObjectData(undefined);
        setUserObject({});
        setLocationName(undefined);
        setSupervisorPrefill(undefined);
        setLindeManagerPrefill(undefined);
        setIntelexLocation(undefined);
        searchUserData('');
    }, [prevBusinessSegment, searchUserData, state.chosenBusinessSegment]);

    useEffect(() => {
        if (userObject?.id) {
            setMandatoryLoading(true);
            //reset all values
            setUserObjectData(undefined);
            setFormValues({
                ...defaultValues,
                language: state.chosenBusinessSegment?.defaultLanguage as string,
            });
            setLocationName(undefined);
            setSupervisorPrefill(undefined);
            setLindeManagerPrefill(undefined);
            setIntelexLocation(undefined);

            api.adHocInvite
                .getUserDetailedInfo(state.user, userObject.id, generateToken(instance, accounts))
                ?.then((res: any) => {
                    setUserObjectData(res);
                    setFormValues(res);
                    if (res.locationInternalId) {
                        api.adHocInvite
                            .getLocationById(
                                state.user,
                                res.locationInternalId,
                                generateToken(instance, accounts),
                            )
                            ?.then((response: any) => {
                                setLocationName(response[0]);
                            });
                    }

                    if (res.supervisorNumber) {
                        setSupervisorPrefillIsLoading(true);
                        api.adHocInvite
                            .getSupervisors(
                                state.user,
                                res.supervisorNumber,
                                generateToken(instance, accounts),
                            )
                            ?.then((response: any) => {
                                setSupervisorPrefill(response[0]);
                                setSupervisorPrefillIsLoading(false);
                            })
                            .catch(() => {
                                setSupervisorPrefillIsLoading(false);
                            });
                    }

                    if (res.lindeManagerCPIDExternal) {
                        api.adHocInvite
                            .getLindeManagers(state.user, generateToken(instance, accounts), {
                                cornerstoneID: '',
                                email: '',
                                firstName: '',
                                lastName: '',
                                user_ref: res.lindeManagerCPIDExternal,
                            })
                            ?.then((response: any) => {
                                setLindeManagerPrefill(response.data[0]);
                            });
                    }

                    if (res.intelexLocationCode) {
                        api.adHocInvite
                            .getIntelexLocationByCode(
                                state.user,
                                res.intelexLocationCode,
                                generateToken(instance, accounts),
                            )
                            ?.then((response: any) => {
                                setIntelexLocation(response);
                                setFormValues((prevValues: any) => {
                                    return {
                                        ...prevValues,
                                        intelexLocation: response.internalId,
                                    };
                                });
                            });
                    }

                    const largestLevel = findLargestLevelWithId(res);

                    if (largestLevel?.id) {
                        if (orgUnits) {
                            const matchedOrgUnit = findOrgUnit([orgUnits], largestLevel.id);
                            console.log('naso, al prop', orgUnits, matchedOrgUnit, largestLevel.id);
                            if (matchedOrgUnit) {
                                setSelectedItem(matchedOrgUnit);
                            }
                        }
                    } else {
                        setSelectedItem(null);
                    }
                    setMandatoryLoading(false);
                });
        }
    }, [userObject]);

    return (
        <>
            {props.page === 'change-data' && (
                <>
                    <InputContainer>
                        <InputLabel htmlFor="location">{t('label.SearchUser')}</InputLabel>
                        <Autocomplete
                            openOnFocus
                            disablePortal
                            id="combo-box-demo"
                            options={usersData}
                            freeSolo={true}
                            onInputChange={(_, value, reason) => {
                                if (value.length > 3 && reason === 'input') {
                                    searchUserData(value, () => {
                                        setLocationName(undefined);
                                        setSupervisorPrefill(undefined);
                                        setLindeManagerPrefill(undefined);
                                        setIntelexLocation(undefined);
                                        supervisorsInput.current?.focus();
                                    });
                                }
                            }}
                            onChange={(_, newValue) => {
                                newValue && setUserObject(newValue);
                            }}
                            sx={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField {...params} inputRef={userInput} variant="standard" />
                            )}
                        />
                        {isLoading && <LinearProgress />}
                        <p
                            style={{
                                margin: 0,
                                fontSize: '11px',
                                color: 'gray',
                            }}
                        >
                            {t('label.threeOrMore')}
                        </p>
                    </InputContainer>

                    {/* {userObjectData && userObjectData.syncElevate &&
                        <Alert sx={{ marginTop: '15px' }} severity="warning">Elevate data update pending.</Alert>
                    }

                    {userObjectData && userObjectData.syncLiDAP &&
                        <Alert sx={{ marginTop: '15px' }} severity="warning">LiDAP data update pending.</Alert>
                    } */}

                    {userObjectData && userObjectData.syncElevate && (
                        <Chip
                            icon={<WarningAmberIcon color="warning" fontSize="small" />}
                            label="Elevate"
                            color="warning"
                            variant="outlined"
                            sx={{ marginRight: '10px' }}
                        />
                    )}
                    {userObjectData && userObjectData.syncLiDAP && (
                        <Chip
                            icon={<WarningAmberIcon color="warning" fontSize="small" />}
                            label="LiDAP"
                            color="warning"
                            variant="outlined"
                        />
                    )}
                </>
            )}
            {userObjectData ? (
                <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                    {userObjectData.userObjectId ? (
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="b2cObjectId">
                                    {t('formField.b2cObjectId')}
                                </InputLabel>
                                <Input
                                    disabled
                                    fullWidth
                                    id="b2cObjectId"
                                    name="FirstName"
                                    value={userObjectData.userObjectId}
                                />
                            </InputContainer>
                        </Grid>
                    ) : null}
                    {userObjectData.userElevateID ? (
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="elevateId">
                                    {t('formField.elevateId')}
                                </InputLabel>
                                <Input
                                    disabled
                                    fullWidth
                                    id="elevateId"
                                    name="elevateId"
                                    value={userObjectData.userElevateID}
                                />
                            </InputContainer>
                        </Grid>
                    ) : null}

                    {userObjectData.userLiDAPID ? (
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="lidapId">{t('formField.lidapId')}</InputLabel>
                                <Input
                                    disabled
                                    fullWidth
                                    id="lidapId"
                                    name="lidapId"
                                    value={userObjectData.userLiDAPID}
                                />
                            </InputContainer>
                        </Grid>
                    ) : null}
                </Grid>
            ) : null}
            {isBusinessSegmentLoading || mandatoryLoading ? (
                // <LinearProgress />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '70vh',
                    }}
                >
                    <CircularProgress />
                </div>
            ) : ((props.page === 'change-data' && userObjectData) || !props.page) &&
              state.appId !== AppIdEnum.LIDAP ? (
                <Box sx={{ width: 'calc(100vh-30px)', margin: '20px', marginTop: '70px' }}>
                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ position: 'relative' }}>
                            <InputContainer>
                                <InputLabel htmlFor="country-code">
                                    {t('label.countryCode')}
                                    {mandatoryFields && mandatoryFields.CountryCode && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="CountryCodeError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Autocomplete
                                    disablePortal
                                    defaultValue={userObjectData?.countryCode}
                                    id="combo-box-demo"
                                    options={countryCodes}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                    onChange={(event: any, value: any) =>
                                        handleAutocompleteChange(value?.id, 'countryCode')
                                    }
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6} sx={{ position: 'relative' }}>
                            <InputContainer>
                                <InputLabel htmlFor="business-relationship">
                                    {t('label.businessRelationship')}
                                    {mandatoryFields && mandatoryFields.BusinessRelationship && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="BusinessRelationshipError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>

                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={businessRelationships}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                    onChange={(event: any, value) => {
                                        handleAutocompleteChange(
                                            value?.externalId,
                                            'businessRelationshipExternalId',
                                        );
                                        handleAutocompleteChange(
                                            value?.id,
                                            'businessRelationshipInternalId',
                                        );
                                    }}
                                    defaultValue={businessRelationships.find(
                                        (option) =>
                                            option.id ===
                                            userObjectData?.businessRelationshipInternalId,
                                    )}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-name">
                                    {t('formField.firstName')}
                                    {mandatoryFields && mandatoryFields.FirstName && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="FirstNameError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    disabled={props.page === 'change-data'}
                                    fullWidth
                                    id="user-name"
                                    name="firstName"
                                    onChange={handleInputChange}
                                    defaultValue={userObjectData?.firstName}
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6}>
                            <InputContainer>
                                <InputLabel htmlFor="user-last-name">
                                    {t('formField.lastName')}
                                    {mandatoryFields && mandatoryFields.LastName && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="LastNameError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    disabled={props.page === 'change-data'}
                                    fullWidth
                                    id="user-last-name"
                                    name="lastName"
                                    onChange={handleInputChange}
                                    defaultValue={userObjectData?.lastName}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-email">
                                    {t('formField.email')}
                                    {mandatoryFields && mandatoryFields.Email && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="EmailError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="EmailInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    disabled={
                                        props.page === 'change-data' && !!userObjectData?.email
                                    }
                                    fullWidth
                                    id="user-email"
                                    name="email"
                                    onChange={handleInputChange}
                                    defaultValue={userObjectData?.email}
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6}>
                            <InputContainer>
                                <InputLabel htmlFor="user-phoneNbr">
                                    {t('label.phoneNumber')}
                                    {mandatoryFields && mandatoryFields.PhoneNbr && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="PhoneError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="PhoneInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-phoneNbr"
                                    name="phoneNbr"
                                    onChange={handleInputChange}
                                    defaultValue={userObjectData?.phoneNbr}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px', position: 'relative' }}>
                            <InputContainer>
                                <InputLabel
                                    htmlFor="external-position"
                                    style={{ marginBottom: '15px' }}
                                >
                                    {t('label.externalPosition')}
                                    {mandatoryFields &&
                                        mandatoryFields.ExternalPositionExternalId && (
                                            <span style={{ color: 'red' }}> *</span>
                                        )}
                                    <Error className="errorMsg" id="ExternalPositionError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Autocomplete
                                    disablePortal
                                    id="external-position"
                                    options={externalPositions}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                    onChange={(event: any, value) => {
                                        handleAutocompleteChange(
                                            value?.id,
                                            'externalPositionInternalId',
                                        );
                                        handleAutocompleteChange(
                                            value?.externalId,
                                            'externalPositionExternalId',
                                        );
                                    }}
                                    defaultValue={externalPositions.find(
                                        (option) =>
                                            option.id ===
                                            userObjectData?.externalPositionInternalId,
                                    )}
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6} sx={{ position: 'relative' }}>
                            {locationLoading && (
                                <LinearProgress
                                    sx={{
                                        position: 'absolute',
                                        left: 48,
                                        right: 0,
                                        bottom: 27,
                                    }}
                                />
                            )}
                            <InputContainer>
                                <InputLabel htmlFor="location">
                                    {t('label.location')}
                                    {mandatoryFields && mandatoryFields.LocationExternalId && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="LocationError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>

                                <Autocomplete
                                    id="location"
                                    openOnFocus
                                    disablePortal
                                    options={searchResults}
                                    getOptionLabel={(option: any) => option.name}
                                    onInputChange={(_, value, reason) => {
                                        if (value.length > 3 && reason === 'input') {
                                            searchLocations(value);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={
                                                locationName && locationName.name
                                                    ? locationName.name
                                                    : t('label.search')
                                            }
                                        />
                                    )}
                                    onChange={(event: any, value) => {
                                        handleAutocompleteChange(
                                            value?.externalId,
                                            'locationExternalId',
                                        );
                                        handleAutocompleteChange(
                                            value?.internalId,
                                            'locationInternalId',
                                        );
                                    }}
                                />
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '11px',
                                        color: 'gray',
                                    }}
                                >
                                    {t('label.threeOrMore')}
                                </p>
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}></Grid>

                    {state.appId === 1 &&
                        state.chosenBusinessSegment?.intelexRequired === 'Yes' && (
                            <>
                                <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                                    <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                                        <InputContainer>
                                            <InputLabel htmlFor="user-title">
                                                {t('formField.PositionTitle')}
                                                {mandatoryFields &&
                                                    mandatoryFields.PositionTitle && (
                                                        <span
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            {' '}
                                                            *
                                                        </span>
                                                    )}
                                            </InputLabel>

                                            <Input
                                                fullWidth
                                                id="user-title"
                                                name="positionTitle"
                                                value={formValues.PositionTitle}
                                                onChange={handleInputChange}
                                                defaultValue={userObjectData?.positionTitle}
                                            />
                                        </InputContainer>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputContainer>
                                            <InputLabel htmlFor="user-company">
                                                {t('formField.myCompany')}
                                            </InputLabel>
                                            {mandatoryFields && mandatoryFields.Company && (
                                                <span style={{ color: 'red' }}> *</span>
                                            )}
                                            <Input
                                                fullWidth
                                                id="user-company"
                                                name="company"
                                                value={formValues.Company}
                                                onChange={handleInputChange}
                                                defaultValue={userObjectData?.company}
                                            />
                                        </InputContainer>
                                    </Grid>
                                </Grid>

                                <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                                    <Grid item xs={6} sx={{ position: 'relative' }}>
                                        {intelexLocationLoading && (
                                            <LinearProgress
                                                sx={{
                                                    position: 'absolute',
                                                    left: 48,
                                                    right: 0,
                                                    bottom: 27,
                                                }}
                                            />
                                        )}
                                        <InputContainer>
                                            <InputLabel htmlFor="location">
                                                {t('label.intelexLocation')}
                                                {mandatoryFields &&
                                                    mandatoryFields.IntelexLocationCode && (
                                                        <span
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            {' '}
                                                            *
                                                        </span>
                                                    )}
                                                <Error
                                                    className="errorMsg"
                                                    id="IntelexLocationError"
                                                >
                                                    {t('errorMessage.RequiredField')}
                                                </Error>
                                            </InputLabel>

                                            <Autocomplete
                                                id="locationIntelex"
                                                openOnFocus
                                                disablePortal
                                                options={intelexLocations}
                                                getOptionLabel={(option: any) => option.name}
                                                onInputChange={(event, value, reason) => {
                                                    if (value.length > 3 && reason === 'input') {
                                                        searchIntelexLocations(value);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label={
                                                            intelexLocation
                                                                ? intelexLocation.name
                                                                : t('label.search')
                                                        }
                                                    />
                                                )}
                                                onChange={(event: any, value) =>
                                                    handleAutocompleteChange(
                                                        value?.code,
                                                        props.page === 'change-data'
                                                            ? 'intelexLocationCode'
                                                            : 'locationCode',
                                                    )
                                                }
                                            />
                                            <p
                                                style={{
                                                    margin: 0,
                                                    fontSize: '11px',
                                                    color: 'gray',
                                                }}
                                            >
                                                {t('label.threeOrMore')}
                                            </p>
                                        </InputContainer>
                                    </Grid>
                                    <Grid item xs sx={{ position: 'relative' }}>
                                        <InputContainer>
                                            {(supervisorLoading || supervisorPrefillIsLoading) && (
                                                <LinearProgress
                                                    sx={{
                                                        position: 'absolute',
                                                        left: 48,
                                                        right: 0,
                                                        bottom: 27,
                                                    }}
                                                />
                                            )}
                                            <InputLabel htmlFor="supervisor">
                                                {t('label.supervisorNumber')}
                                                {mandatoryFields &&
                                                    mandatoryFields.SupervisorNumber && (
                                                        <span
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            {' '}
                                                            *
                                                        </span>
                                                    )}
                                                <Error className="errorMsg" id="SupervisorError">
                                                    {t('errorMessage.RequiredField')}
                                                </Error>
                                            </InputLabel>

                                            <Autocomplete
                                                id="supervisor"
                                                openOnFocus
                                                disablePortal
                                                options={supervisors}
                                                getOptionLabel={(option: any) => option.name}
                                                onInputChange={(_, value, reason) => {
                                                    if (value.length > 3 && reason === 'input') {
                                                        searchSupervisors(value, () =>
                                                            supervisorsInput.current?.focus(),
                                                        );
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label={
                                                            supervisorPrefill &&
                                                            supervisorPrefill.name
                                                                ? supervisorPrefill.fullName +
                                                                  ' ' +
                                                                  '(' +
                                                                  supervisorPrefill.number +
                                                                  ')' +
                                                                  ' ' +
                                                                  supervisorPrefill.email
                                                                : t('label.search')
                                                        }
                                                    />
                                                )}
                                                onChange={(event: any, value) =>
                                                    handleAutocompleteChange(
                                                        value?.id,
                                                        'supervisorNumber',
                                                    )
                                                }
                                            />
                                            <p
                                                style={{
                                                    margin: 0,
                                                    fontSize: '11px',
                                                    color: 'gray',
                                                }}
                                            >
                                                {t('label.threeOrMore')}
                                            </p>
                                        </InputContainer>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px', position: 'relative' }}>
                            {languageLoading && (
                                <LinearProgress
                                    sx={{
                                        position: 'absolute',
                                        left: 48,
                                        right: 0,
                                        bottom: 10,
                                    }}
                                />
                            )}
                            <InputContainer>
                                <InputLabel htmlFor="user-title">
                                    {t('label.language')}
                                    {mandatoryFields && mandatoryFields.Language && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="LanguageError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Autocomplete
                                    openOnFocus
                                    disablePortal
                                    id="combo-box-demo"
                                    options={languageOptions}
                                    onChange={(event, value) =>
                                        handleAutocompleteChange(value?.value, 'language')
                                    }
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={
                                                formValues.language &&
                                                formValues.language.slice(0, 2)
                                            }
                                        />
                                    )}
                                    defaultValue={languageOptions.find(
                                        (option: { value: string }) =>
                                            option.value === userObjectData?.language,
                                    )}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <div>
                        {cornerstoneLabels.length > 0 && (
                            <>
                                <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                                    <CornerStoneData>
                                        <legend>{t('label.csData')}</legend>
                                        {cornerstoneLabels.map((label) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={3}
                                                    sx={{
                                                        marginBottom: 'px',
                                                    }}
                                                >
                                                    <InputContainer>
                                                        <InputLabel htmlFor={label.parameter}>
                                                            {label.label}
                                                        </InputLabel>
                                                        <Input
                                                            fullWidth
                                                            id={label.parameter}
                                                            name={label.parameter}
                                                            onChange={handleInputChange}
                                                            defaultValue={
                                                                userObjectData
                                                                    ? userObjectData[
                                                                          label.parameter as keyof CreateAdHocInviteForm
                                                                      ]
                                                                    : undefined
                                                            }
                                                        />
                                                        <Error
                                                            className="errorMsg"
                                                            id="CSDataError"
                                                        >
                                                            {t('errorMessage.RequiredField')}
                                                        </Error>
                                                    </InputContainer>
                                                </Grid>
                                            );
                                        })}
                                    </CornerStoneData>
                                </Grid>
                            </>
                        )}
                    </div>

                    {userSupportInfo && userSupportInfo.length > 0 && (
                        <>
                            <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                                <CornerStoneData>
                                    <legend>User Support Info Hierarchies:</legend>
                                    {userSupportInfo.map((label: any) => {
                                        if (label !== undefined) {
                                            return (
                                                <Grid item xs={3} sx={{ marginBottom: 'px' }}>
                                                    <InputContainer>
                                                        <InputLabel htmlFor={label.parameter}>
                                                            {label.label}
                                                        </InputLabel>
                                                        <Input
                                                            fullWidth
                                                            id={label.parameter}
                                                            name={label.parameter}
                                                            onChange={handleInputChange}
                                                            defaultValue={
                                                                userObjectData?.[label.parameter]
                                                            }
                                                        />
                                                        <Error className="errorMsg" id="USIHError">
                                                            {t('errorMessage.RequiredField')}
                                                        </Error>
                                                    </InputContainer>
                                                </Grid>
                                            );
                                        }
                                    })}
                                </CornerStoneData>
                            </Grid>
                        </>
                    )}

                    {supportInfo && supportInfo.length > 0 && (
                        <>
                            <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                                <CornerStoneData>
                                    <legend>Support Info:</legend>
                                    {supportInfo.map((label: any) => {
                                        if (label !== undefined) {
                                            return (
                                                <Grid item xs={3} sx={{ marginBottom: 'px' }}>
                                                    <InputContainer>
                                                        <InputLabel htmlFor={label.parameter}>
                                                            {label.label}
                                                        </InputLabel>
                                                        <Input
                                                            fullWidth
                                                            id={label.parameter}
                                                            name={label.parameter}
                                                            onChange={handleInputChange}
                                                            defaultValue={
                                                                userObjectData?.[label.parameter]
                                                            }
                                                        />
                                                        <Error className="errorMsg" id="SIError">
                                                            {t('errorMessage.RequiredField')}
                                                        </Error>
                                                    </InputContainer>
                                                </Grid>
                                            );
                                        }
                                    })}
                                </CornerStoneData>
                            </Grid>
                        </>
                    )}
                    <AdHocManagersFields
                        requiredMessage={lindeManagerRequired}
                        mandatory={mandatoryFields?.LindeManagerCPID}
                        handleAutocompleteChange={handleManagerSelection}
                        mandatoryOnSubmit={managersMandatoryOnSubmit}
                        defaultManager={lindeManagerPrefill}
                    />

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ position: 'relative' }}>
                            {orgUnits && (
                                <TreeSearch
                                    data={orgUnits}
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                    setSelectedItem={handleSelectOrgUnit}
                                    selectedItem={selectedItem}
                                    mandatory={mandatoryFields}
                                />
                            )}
                            {orgUnits && searchQuery === '' && (
                                <TreeView
                                    children={orgUnits}
                                    selectedItem={selectedItem}
                                    setSelectedItem={handleSelectOrgUnit}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={4} sx={{ marginTop: '10px' }}>
                        {isSubmitLoading ? (
                            <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '10px' }}>
                                <CircularProgress sx={{ width: '100%' }} />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                                    <Button
                                        startIcon={<SaveIcon />}
                                        type="submit"
                                        variant="outlined"
                                        onClick={handleSubmit}
                                    >
                                        {t('formField.save')}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            ) : state.appId === AppIdEnum.LIDAP && userObjectData ? (
                <>
                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-name">
                                    {t('formField.firstName')}
                                    {mandatoryFields && mandatoryFields.FirstName && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="FirstNameError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    disabled={props.page === 'change-data'}
                                    fullWidth
                                    id="user-name"
                                    name="firstName"
                                    onChange={handleInputChange}
                                    defaultValue={userObjectData?.firstName}
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6}>
                            <InputContainer>
                                <InputLabel htmlFor="user-last-name">
                                    {t('formField.lastName')}
                                    {mandatoryFields && mandatoryFields.LastName && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="LastNameError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    disabled={props.page === 'change-data'}
                                    fullWidth
                                    id="user-last-name"
                                    name="lastName"
                                    onChange={handleInputChange}
                                    defaultValue={userObjectData?.lastName}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-email">
                                    {t('formField.email')}
                                    {mandatoryFields && mandatoryFields.Email && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="EmailError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="EmailInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    disabled={
                                        props.page === 'change-data' && !!userObjectData?.email
                                    }
                                    fullWidth
                                    id="user-email"
                                    name="email"
                                    onChange={handleInputChange}
                                    defaultValue={userObjectData?.email}
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6}>
                            <InputContainer>
                                <InputLabel htmlFor="user-phoneNbr">
                                    {t('label.phoneNumber')}
                                    {mandatoryFields && mandatoryFields.PhoneNbr && (
                                        <span style={{ color: 'red' }}> *</span>
                                    )}
                                    <Error className="errorMsg" id="PhoneError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                    <Error className="errorMsg" id="PhoneInvalid">
                                        {t('errorMessage.InvalidInput')}
                                    </Error>
                                </InputLabel>
                                <Input
                                    fullWidth
                                    id="user-phoneNbr"
                                    name="phoneNbr"
                                    onChange={handleInputChange}
                                    defaultValue={userObjectData?.phoneNbr}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ position: 'relative' }}>
                            {intelexLocationLoading && (
                                <LinearProgress
                                    sx={{
                                        position: 'absolute',
                                        left: 48,
                                        right: 0,
                                        bottom: 27,
                                    }}
                                />
                            )}
                            <InputContainer>
                                <InputLabel htmlFor="location">
                                    {t('label.intelexLocation')}
                                    {mandatoryFields && mandatoryFields.IntelexLocationCode && (
                                        <span
                                            style={{
                                                color: 'red',
                                            }}
                                        >
                                            {' '}
                                            *
                                        </span>
                                    )}
                                    <Error className="errorMsg" id="IntelexLocationError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>

                                <Autocomplete
                                    id="locationIntelex"
                                    openOnFocus
                                    disablePortal
                                    options={intelexLocations}
                                    getOptionLabel={(option: any) => option.name}
                                    onInputChange={(event, value, reason) => {
                                        if (value.length > 3 && reason === 'input') {
                                            searchIntelexLocations(value);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={
                                                intelexLocation
                                                    ? intelexLocation.name
                                                    : t('label.search')
                                            }
                                        />
                                    )}
                                    onChange={(event: any, value) =>
                                        handleAutocompleteChange(
                                            value?.code,
                                            props.page === 'change-data'
                                                ? 'intelexLocationCode'
                                                : 'locationCode',
                                        )
                                    }
                                />
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '11px',
                                        color: 'gray',
                                    }}
                                >
                                    {t('label.threeOrMore')}
                                </p>
                            </InputContainer>
                        </Grid>
                        <Grid item xs sx={{ position: 'relative' }}>
                            <InputContainer>
                                {(supervisorLoading || supervisorPrefillIsLoading) && (
                                    <LinearProgress
                                        sx={{
                                            position: 'absolute',
                                            left: 48,
                                            right: 0,
                                            bottom: 27,
                                        }}
                                    />
                                )}
                                <InputLabel htmlFor="supervisor">
                                    {t('label.supervisorNumber')}
                                    {mandatoryFields && mandatoryFields.SupervisorNumber && (
                                        <span
                                            style={{
                                                color: 'red',
                                            }}
                                        >
                                            {' '}
                                            *
                                        </span>
                                    )}
                                    <Error className="errorMsg" id="SupervisorError">
                                        {t('errorMessage.RequiredField')}
                                    </Error>
                                </InputLabel>

                                <Autocomplete
                                    id="supervisor"
                                    openOnFocus
                                    disablePortal
                                    options={supervisors}
                                    getOptionLabel={(option: any) => option.name}
                                    onInputChange={(_, value, reason) => {
                                        if (value.length > 3 && reason === 'input') {
                                            searchSupervisors(value, () =>
                                                supervisorsInput.current?.focus(),
                                            );
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={
                                                supervisorPrefill && supervisorPrefill.name
                                                    ? supervisorPrefill.fullName +
                                                      ' ' +
                                                      '(' +
                                                      supervisorPrefill.number +
                                                      ')' +
                                                      ' ' +
                                                      supervisorPrefill.email
                                                    : t('label.search')
                                            }
                                        />
                                    )}
                                    onChange={(event: any, value) =>
                                        handleAutocompleteChange(value?.id, 'supervisorNumber')
                                    }
                                />
                                <p
                                    style={{
                                        margin: 0,
                                        fontSize: '11px',
                                        color: 'gray',
                                    }}
                                >
                                    {t('label.threeOrMore')}
                                </p>
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-title">
                                    {t('formField.PositionTitle')}
                                    {mandatoryFields && mandatoryFields.PositionTitle && (
                                        <span
                                            style={{
                                                color: 'red',
                                            }}
                                        >
                                            {' '}
                                            *
                                        </span>
                                    )}
                                </InputLabel>

                                <Input
                                    fullWidth
                                    id="user-title"
                                    name="positionTitle"
                                    value={formValues.PositionTitle}
                                    onChange={handleInputChange}
                                    defaultValue={userObjectData?.positionTitle}
                                />
                            </InputContainer>
                        </Grid>

                        <Grid item xs={6}>
                            <InputContainer>
                                <InputLabel htmlFor="user-company">
                                    {t('formField.myCompany')}
                                </InputLabel>
                                {mandatoryFields && mandatoryFields.Company && (
                                    <span style={{ color: 'red' }}> *</span>
                                )}
                                <Input
                                    fullWidth
                                    id="user-company"
                                    name="company"
                                    value={formValues.Company}
                                    onChange={handleInputChange}
                                    defaultValue={userObjectData?.company}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={6} sx={{ marginTop: '15px' }}>
                        <Grid item xs={6} sx={{ marginBottom: 'px' }}>
                            <InputContainer>
                                <InputLabel htmlFor="user-title">Language</InputLabel>
                                <Autocomplete
                                    openOnFocus
                                    disablePortal
                                    defaultValue={{
                                        id: 'en-US',
                                        label: 'en-US',
                                    }}
                                    id="combo-box-demo"
                                    options={[{ id: 'en-US', label: 'en-US' }]}
                                    onChange={(event, value) =>
                                        handleAutocompleteChange(value, 'language')
                                    }
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                />
                            </InputContainer>
                        </Grid>
                    </Grid>

                    <Grid container columnSpacing={4} sx={{ marginTop: '10px' }}>
                        {isSubmitLoading ? (
                            <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '10px' }}>
                                <CircularProgress sx={{ width: '100%' }} />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                                    <Button
                                        startIcon={<SaveIcon />}
                                        type="submit"
                                        variant="outlined"
                                        onClick={handleSubmit}
                                    >
                                        {t('formField.save')}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </>
            ) : null}
        </>
    );
};

export default AdHocElevateOnline;
