import { IconButton, Input, InputLabel } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../../context';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { Error } from '../../styled';
import { MandatoryField } from '../../types/mandatoryField';

export function getLargestOrgLevel(mandatoryField?: MandatoryField): number {
    if (!mandatoryField) {
        return 0;
    }
    const levels = Object.keys(mandatoryField)
        .filter(
            (key) =>
                key.startsWith('OrgUnitLevel') &&
                key.endsWith('ExternalId') &&
                mandatoryField[key as keyof MandatoryField],
        )
        .map((key) => parseInt(key.match(/\d+/)?.[0] || '0', 10));

    return levels.length > 0 ? Math.max(...levels) : 0; // Return 0 if no level is true
}

function TreeSearch({
    data,
    searchQuery,
    setSearchQuery,
    selectedItem,
    setSelectedItem,
    mandatory,
}: any) {
    const { state, dispatch } = useContext(UserContext);
    const { t } = useTranslation();

    const handleSearchInputChange = (event: any) => {
        setSearchQuery(event.target.value);
    };

    function flatten(node: any, arr: any, query: string) {
        arr.push(node.data);
        if (node.children) {
            node.children.forEach((child: any) => {
                flatten(child, arr, searchQuery);
            });
        }
    }

    let flatData: any = [];
    data && flatten(data, flatData, searchQuery);

    function handleClick(item: any) {
        setSelectedItem(item);
        dispatch({ type: 'SET_SELECTED_ORGUNIT', data: item });
        setSearchQuery('');
    }

    const orgUnitRequiredLvl = getLargestOrgLevel(mandatory as MandatoryField);

    return (
        <div>
            <div>
                <InputLabel htmlFor="user-last-name">
                    {t('label.orgUnits')}{' '}
                    {mandatory?.OrgUnitLevel1ExternalId ? (
                        <span style={{ color: 'red' }}>*</span>
                    ) : null}
                    <Error className="errorMsg" id="OrgLvlError">
                        {t('errorMessage.OrgUnitLevel', { number: orgUnitRequiredLvl })}
                    </Error>
                </InputLabel>
                <div style={{ display: 'flex' }}>
                    <Input
                        fullWidth
                        placeholder={
                            selectedItem?.data?.name ||
                            selectedItem?.name ||
                            t('label.searchOrChoose')
                        }
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                    />
                    <IconButton
                        onClick={() => {
                            dispatch({ type: 'SET_SELECTED_ORGUNIT', data: null });
                            setSelectedItem(null);
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </div>
            </div>
            {searchQuery !== '' &&
                flatData &&
                flatData.map((data: any) => {
                    if (
                        data !== undefined &&
                        data.name.toLowerCase().includes(searchQuery.toLowerCase())
                    ) {
                        return (
                            data && (
                                <div onClick={() => handleClick(data)}>
                                    <p
                                        style={{
                                            padding: '2px 6px',
                                            marginLeft: 2,
                                            marginTop: 1,
                                            marginBottom: 1,
                                            cursor: 'pointer',
                                            backgroundColor:
                                                state.orgUnit &&
                                                data.internalId === state.orgUnit.internalId
                                                    ? '#dfeeff'
                                                    : 'white',
                                        }}
                                    >
                                        {data.name}
                                    </p>
                                </div>
                            )
                        );
                    }
                })}
        </div>
    );
}

export default TreeSearch;
