import React, { useState, useContext } from 'react';
import { UserContext } from '../../context';
import AdHocElevate from '../adhoc-invites/AdHocElevate';
import AdHocLidap from '../adhoc-invites/AdHocLidap';

const ChangeUserData: React.FC = () => {
    const { state } = useContext(UserContext);

    return (
        <>
            {state.appId === 3 ? (
                <AdHocElevate page="change-data" />
            ) : state.appId === 1 || state.appId === 8 ? (
                <AdHocElevate page="change-data" />
            ) : null}
        </>
    );
};

export default ChangeUserData;
